import React, { useState } from "react";
import "../components/underConstruction.css";

import logoImg from "../assets/home/img/icons/Logo/png/logo-no-background.png";
import videoDemo from "../assets/hci/groupProject1/video/buffify_video_demo.mp4";

import musicMixer from "../assets/hci/groupProject1/InitialSketches/MusicMixer.png";
import animeSelector from "../assets/hci/groupProject1/InitialSketches/AnimeSelector.png";
import animeThemedMiniGame from "../assets/hci/groupProject1/InitialSketches/AnimeThemedMiniGame.png";
import catMiniGame from "../assets/hci/groupProject1/InitialSketches/CatMiniGame.png";
import workoutPlanner from "../assets/hci/groupProject1/InitialSketches/WorkoutPlanner.png";
import codePractice from "../assets/hci/groupProject1/InitialSketches/CodePractice.png";
import buffify from "../assets/hci/groupProject1/InitialSketches/Buffify.png";
import gamingMechanicPracticeSelector from "../assets/hci/groupProject1/InitialSketches/GamingMechanicPracticeSelector.png";
import foreignLanguagePractice from "../assets/hci/groupProject1/InitialSketches/ForeignLanguagePractice.png";
import dayPlanner from "../assets/hci/groupProject1/InitialSketches/DayPlanner.png";

import selfieCatTransformation from "../assets/hci/groupProject1/RefinedSketches/SelfieCatTransformation.png";
import benchPressCatSelfieProgress from "../assets/hci/groupProject1/RefinedSketches/BenchPressCatSelfieProgress.png";
import benchPressCatProgressBar from "../assets/hci/groupProject1/RefinedSketches/BenchPressCatProgressBar.png";

const ATaleOfTwoButtons = () => {
  // Array of images with captions
  const initialSketches = [
    {
      src: musicMixer,
      alt: "Music Mixer Sketch",
      caption:
        "Music Mixer Sketch: A music shuffling application. There are two buttons (Radio Station) and (Next Song), and an avatar in the center of the screen. When you click on (Radio Station) the genre of music changes and when you click on (Next Song) the song changes within the genre.",
    },
    {
      src: animeSelector,
      alt: "Anime Selector Sketch",
      caption:
        "Anime Selector Sketch: An anime show shuffler. There are two buttons, (Category) which shuffles through anime categories and (Roulette Wheel) where the different animes within a category appea. Once you click to spin the wheel, a random anime within the category is selected for you to watch.",
    },
    {
      src: animeThemedMiniGame,
      alt: "Anime Themed Minigame Sketch",
      caption:
        "Anime Themed Minigame Sketch - An anime themed minigame. There is an anime duo that appears in the center of the screen. The goal of the application is to select the correct title for the duo. i.e., friends, rivials, lovers, frienemies, etc. The two types of buttons is the titles and the (next) button which allows the user to skip to the next duo.",
    },
    {
      src: catMiniGame,
      alt: "Cat Minigame Sketch",
      caption:
        "Cat Minigame Sketch: A cat minigame. The is a cat avatar in the center of the screen. There are two buttons, (Pet) is to pet the cat and (Food) is to feed the cat. If you click too much of the (Pet) button the cat will attack you for over-stimulating it. If you click too much of the (Food) button the cat will explode from being overfed.",
    },
    {
      src: workoutPlanner,
      alt: "Workout Planner Sketch",
      caption:
        "Workout Planner Sketch: A workout/relax planner. There are two buttons, (Relax with Cat) which if you click on the cat will give you a funny cat fact. If you click on the (Workout with Cat) button, the cat will give you an exercise to do.",
    },
    {
      src: codePractice,
      alt: "Code Practice Sketch",
      caption:
        "Code Practice Sketch: Coding minigame. There is a snippet of code on the screen and there are two buttons. The first button is the (bug) in the code, the second button is the rest of the code snippet. If you click on the bug the background turns gold and if you click on the rest of the code the screen turns red. Gold signals that you found the bug. Red signals that you did not find the bug. Both lead you to a new code snippet.",
    },
    {
      src: buffify,
      alt: "Buffify Sketch",
      caption:
        "Buffify Sketch: Cute Cate Buffifer. There are two buttons, when you click on (Cute Photo) the background changes into a cute photo of a cat avatar. When you click on (Buffify)the cute cat is replaced with a buff cat avatar.",
    },
    {
      src: gamingMechanicPracticeSelector,
      alt: "Gaming Mechanic Practice Selector Sketch",
      caption:
        "Gaming Mechanic Practice Selector Sketch: A roulette spinner for practicing gaming mechanics. There are two buttons, (Category) which changes the categories for games and a (Spin) button which spins the roulette wheel with different gaming mechanics to practice.",
    },
    {
      src: foreignLanguagePractice,
      alt: "Foreign Language Practice Sketch",
      caption:
        "Foreign Language Practice Sketch: Choose the right word minigame. There is an image of a food item on the left and different words in Japanese and Korean that represent the item. Your goal is to choose the right word. So the two button types would be the (right) answer and all the other (wrong) answers. Once you choose, the next item will appear on the screen. Green if you got it right, red if you got it wrong.",
    },
    {
      src: dayPlanner,
      alt: "Day Planner Sketch",
      caption:
        "Day Planner Sketch: A day planner questionnaire. There is a question that pops up on the screen asking if you to choose a relaxing task or productive task. You can use two buttons to answer, (yes) or (no). If you answer no, the next question pops up. If you answer yes, the day progress gauge goes up and the next question is prompted.",
    },
  ];

  const refinedSketches = [
    {
      src: selfieCatTransformation,
      alt: "Selfie Cat Transformation",
      caption:
        "Selfie Cat Transformation: The cat avatar would be displayed in the center of the screen, taking a selfie. When the user presses the (workout) or (eat) buttons, the cat's body shape would change dynamically to reflect the choice, showcasing the transformation.",
    },
    {
      src: benchPressCatSelfieProgress,
      alt: "Bench Press Cat Selfie Progress",
      caption:
        "Bench Press Cat Selfie Progress: Cat with Selfie Progress: In this version, the cat avatar would be shown doing a bench press. Depending on which button the user presses, the cat would either perform more bench presses or eat food. Progress would be tracked via a selfie image in the corner of the screen, which would update dynamically to reflect changes in the cat's appearance.",
    },
    {
      src: benchPressCatProgressBar,
      alt: "Bench Press Cat Progress Bar",
      caption:
        "Bench Press Cat Progress Bar: Similar to the second idea, this version also features the cat doing a bench press. However, progress would be displayed through progress bars in the corner of the screen instead of a selfie image.",
    },
  ];

  // State for initial sketches carousel
  const [currentInitialIndex, setCurrentInitialIndex] = useState(0);

  // State for refined sketches carousel
  const [currentRefinedIndex, setCurrentRefinedIndex] = useState(0);

  const nextInitial = () => {
    setCurrentInitialIndex((currentInitialIndex + 1) % initialSketches.length);
  };

  const prevInitial = () => {
    setCurrentInitialIndex(
      (currentInitialIndex - 1 + initialSketches.length) %
        initialSketches.length
    );
  };

  const nextRefined = () => {
    setCurrentRefinedIndex((currentRefinedIndex + 1) % refinedSketches.length);
  };

  const prevRefined = () => {
    setCurrentRefinedIndex(
      (currentRefinedIndex - 1 + refinedSketches.length) %
        refinedSketches.length
    );
  };

  return (
    <div className="container">
      <div className="section-header">
        <div className="header-container">
          <a className="header-logo-anchor" href="/">
            <img
              className="header-logo-img"
              src={logoImg}
              alt={"Portfolio Logo"}
            />
          </a>
          <div className="header-center">A Tale of Two Buttons - Buffify</div>
          <div className="header-links">
            <a
              className="header-nav-option"
              href="/human-computer-interactions"
            >
              HCI Projects
            </a>
          </div>
        </div>
      </div>

      <div className="section-project">
        {/* Project Hero */}
        <div className="project-hero">
          <div className="project-header">
            <div className="project-title">Buffify</div>
            {/* <div className="project-subtitle">Group Project 1</div> */}
            <div className="groupmates">
              Alan Huynh, Kevin Hoang, Naheen Kabir
            </div>
          </div>

          <div className="project-overview">
            <div className="project-overview-title">
              Project Overview: "Group Project 1 - A Tale of Two Buttons"
            </div>
            <p className="sketch-decription">
              Our group project, titled <i>Buffify</i>, was designed around one
              of our group members, Kevin Hoang, to illustrate aspects of his
              personality. As part of the assignment, we developed an
              application featuring two interactive buttons. Rather than each
              button representing a unique trait, they work together to tell a
              story about Kevin's interests and characteristics.
            </p>
            <p className="sketch-decription">
              From working with Kevin, we learned that he likes to stay busy,
              has a passion for mastering video games, enjoys watching anime and
              listening to a wide variety of music genres, and stays active
              through physical fitness. He also has a deep interest in computer
              programming, traveling, particularly planning future trips to
              Japan and Korea, and has a fondness for cats. These traits served
              as the inspiration for the design and functionality of
              <i> Buffify</i>.
            </p>
          </div>
        </div>

        {/* Project Initial Sktches */}
        <div className="project-initialSketch-container">
          <div className="left-col">
            <div className="project-initialSketch-title">
              Initial Sketches: Design Process for Buffify
            </div>
            <p className="sketch-decription">
              After learning about Kevin's personality, the next step for our
              group was to brainstorm ideas for creating an application that
              would represent different aspects of Kevin's interests. Each group
              member was tasked with producing 10 initial sketches of potential
              two-button interactive applications, each reflecting an element of
              Kevin&apos; personality.
            </p>
            <p className="sketch-decription">
              For my 10 initial sketches, I kept in mind Kevin&apos;s
              personality and considered how he might use the application. I
              categorized my ideas into two broader themes: increasing
              productivity and enhancing leisure time.
            </p>
            <p className="sketch-decription">
              Since Kevin is majoring in Computer Science, enjoys mastering
              video games, and has plans to travel, I focused some of my
              sketches on increasing his productivity in these areas. These
              sketches included day planning, workout planning, a gaming
              mechanic practice selector, coding practice, and foreign language
              practice.
            </p>
            <p className="sketch-decription">
              Given Kevin's love for cats, music, and anime, I also designed
              sketches aimed at enhancing his leisure time. These included a
              music mixer, an anime selector, an anime-themed mini-game, a cat
              mini-game, and a fun &quot;cute cat buffifier&quot; &#40;Buffify
              &#41;.
            </p>
            <p className="sketch-decription">
              For more details about the sketches, please view the carousel on
              the left, which showcases all 10 initial designs.
            </p>
          </div>
          <div className="right-col">
            <div className="carousel">
              <img
                className="initial-sketch"
                src={initialSketches[currentInitialIndex].src}
                alt={initialSketches[currentInitialIndex].alt}
              />
              <p className="caption">
                {initialSketches[currentInitialIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button className="carousel-button prev" onClick={prevInitial}>
                  Previous
                </button>
                <button className="carousel-button next" onClick={nextInitial}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Project Refine Sketch */}
        <div className="project-refinedSketch-container">
          <div className="left-col">
            <div className="carousel">
              <img
                className="refined-sketch"
                src={refinedSketches[currentRefinedIndex].src}
                alt={refinedSketches[currentRefinedIndex].alt}
              />
              <p className="caption">
                {refinedSketches[currentRefinedIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button className="carousel-button prev" onClick={prevRefined}>
                  Previous
                </button>
                <button className="carousel-button next" onClick={nextRefined}>
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="right-col">
            <div className="project-refinedSketch-title">
              Refined Sketches: Buffify's Development
            </div>
            <p className="sketch-decription">
              After each group member created 10 initial sketches, we reviewed
              all the designs together and selected a few ideas that we believed
              were the most creative and practical for further refinement. Our
              decision was based on the uniqueness of the concept as well as how
              feasible it would be to implement these ideas in an actual
              application.
            </p>
            <p className="sketch-decription">
              Among the collection of sketches, we noticed a common interest in
              the Music Selector and Beat Maker concepts, which reflected
              Kevin's love for music. Additionally, we were all intrigued by the
              concept of Buffify—the "Cute Cat Buffifier." As a result, we
              decided to create 10 refined sketches focused on three main ideas:
              a Music Selector, a Beat Maker, and the Cute Cat Buffifier.
            </p>
            <p className="sketch-decription">
              For my refined sketches, I primarily focused on expanding my
              original idea of Buffify (the Cute Cat Buffifier). The initial
              concept involved two buttons: one to display a cat photo and
              another to "Buffify" the cat. I evolved this idea to align more
              closely with Kevin&apos;s fitness interests by focusing on making
              the cat engage in physical activity to grow stronger. My refined
              sketches centered around two buttons: one for working out and
              another for eating.
            </p>
            <p className="sketch-decription">
              I explored three main directions for how the application could
              evolve based on these two buttons:
            </p>
          </div>
        </div>

        {/* Project Final Product */}
        <div className="project-finalProduct-container">
          <div className="project-finalProduct-title">
            Final Design: Buffify Application
          </div>
          <div className="project-video">
            <video controls class="video-demo" src={videoDemo} />
          </div>
          <p className="sketch-decription">
            In the final step of our project, the group reviewed all the refined
            sketches and collectively decided on the final design for the
            application. The chosen design features a dynamic cat avatar at the
            center of the screen, along with a selfie progress image in the top
            right corner that updates based on the cat's activities.
          </p>
          <p className="sketch-decription">
            As for the two interactive buttons, one is a joystick that can be
            moved up or to the right. Moving the joystick up causes the cat to
            lift a barbell, while moving it to the right makes the cat run on a
            treadmill. The second button is a food button, which, when pressed,
            makes the cat eat.
          </p>
          <p className="sketch-decription">
            The selfie progress image changes dynamically depending on how much
            the cat works out or eats. It&apos;s up to the user to find the
            perfect balance between exercise and eating to customize the
            cat&apos;s appearance, allowing for a personalized experience!
          </p>
          <div className="project-demo-link">
            <button className="project-btn">
              <a
                className="project-link"
                href="https://buffify581a1.glitch.me/"
              >
                Buffify Demo
              </a>
            </button>
          </div>
        </div>

        <div className="project-contribution-container">
          <div className="left-col">
            <div className="project-contribution-title">Contribution:</div>
            <p className="sketch-decription">
              <ul className="contribution-list">
                <li className="contribution-bullet">
                  Application layout - Alan
                </li>
                <li className="contribution-bullet">Avatar Gif - Kevin</li>
                <li className="contribution-bullet">Joystick Logic - Naheen</li>
                <li className="contribution-bullet">
                  Selfie Logic and Images - Alan
                </li>
                <li className="contribution-bullet">
                  Video Demonstration - Kevin
                </li>
                <li>Presentation - Kevin</li>
              </ul>
            </p>
          </div>
          <div className="right-col">
            <div className="project-contribution-title">
              Source Code & References:
            </div>
            <p className="sketch-decription">
              <ul className="contribution-list references">
                <li className="contribution-bullet">
                  <a href="https://github.com/TheAlanHuynh/CPSC581_GroupProject1">
                    Source Code
                  </a>
                </li>
                <li className="contribution-bullet">
                  Refernces:
                  <ul>
                    <li>Joystick Logic: GitHub Copilot AI</li>
                    <li>Selfie Images: Generated by ChatGPT's Dall-e</li>
                    <li>Cat Avatar Gif: Generated by Viergacht</li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="section-footer-project">
        <div className="footer-container">
          <div className="footer-credit">
            <p>Background generated by DALL-E</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ATaleOfTwoButtons;
