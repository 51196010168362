import React from "react";
import "../components/underConstruction.css";

import logoImg from "../assets/home/img/icons/Logo/png/logo-no-background.png";
import constructionImg from "../assets/home/img/background/AI_construction.webp";

const UnderConstruction = () => {
  return (
    <div className="container">
      <div className="section-header">
        <div className="header-container">
          <a className="header-logo-anchor" href="/">
            <img
              className="header-logo-img"
              src={logoImg}
              alt={"Portfolio Logo"}
            />
          </a>
          <div className="header-right-side">
            <a
              className="header-nav-option"
              href="/human-computer-interactions"
            >
              Human Computer Interactions II - Projects
            </a>
          </div>
        </div>
      </div>

      <div className="section-main">
        <div className="main-message">
          <h1 className="message-title">Website under construction</h1>
          <img
            className="message-img"
            src={constructionImg}
            alt={"Website Under Construction Sign"}
          />
          <p className="message-subtitle">
            Sorry the main page is under construction!
          </p>
          <p className="message-description">
            Checkout my HCL Projects while you're here!!
          </p>
          <button className="project-btn">
            <a className="project-link" href="/human-computer-interactions">
              HCI Projects
            </a>
          </button>
        </div>
      </div>

      <div className="section-footer">
        <div className="footer-container">
          <div className="footer-credit">
            <p>Background generated by DALL-E</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
